<template>
<div style="padding: 10px">
  <div style="text-align: center;margin-top: 20px;color: #323233;font-size: 28px">
    电话报警服务充值
  </div>
  <div style="margin-top: 50px">
    <van-field v-model="amount" type="digit" label="金额" />
  </div>
  <div style="padding-top: 30px">
    <van-button @click="pay" block type="primary">{{$t('微信支付')}}</van-button>
  </div>
  <div style="margin-top: 20px">
    <div style="color: #646566;font-size: 22px">
      购买说明
    </div>
    <div style="color: #969799;font-size: 15px;line-height: 22px">
      1.电话报警服务为虚拟商品，购买后不支持退款
    </div>
  </div>
</div>
</template>

<script>
import {readCommonParam,getImei} from "../../util/session-storage-utils";
import {Field,Button,  Notify, Dialog} from "vant"
import {checkOrder, createOrder} from "../../api/pay";
import {orderPay} from "../../util/jsinterfaces-util";
export default {
  name: "Pay",
  components:{
    [Field.name]:Field,
    [Button.name]:Button,
  },
  created() {
    readCommonParam(this)
    this.loadData();
  },
  data(){
    return{
      amount:10,
      rechargeId:2
    }
  },
  methods:{
    loadData(){

    },
    checkOrder(orderNo){
      checkOrder(orderNo).then(res => {
        let result = res.data;
        if(result){
          Notify({ type: 'success', message: this.$t('支付成功') });
          this.loadData();
        }else{
          Notify({ type: 'danger', message: this.$t('支付失败') });
        }
      })
    },
    pay(){
      if(this.rechargeId == null){
        Notify(this.$t('请选择支付项'));
        return
      }
      createOrder(getImei(),this.rechargeId,this.amount).then(res => {
        let order = res.data;

        orderPay(order.orderNo,1);
        setTimeout(()=>{
          Dialog.confirm({
            title: this.$t('支付确认'),
            message: this.$t('订单支付成功'),
          })
              .then(() => {
                // on confirm
                this.checkOrder(order.orderNo);
              })
              .catch(() => {
                // on cancel
              });
        },500)

      })

    }
  }

}
</script>

<style scoped>
.device-info{
  font-size: 14px;
  padding: 10px;
  margin: 10px 0;
  background-color: #FFFFFF;

}
.device-info div {
  padding: 5px 0;
}
</style>
